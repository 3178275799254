
import { defineComponent } from "vue";
import { RestResources } from "../../../rest-resource";
import ResourceList from "../../../components/resources/resources-list.vue";
import ResourceDropdown from "../../../components/resources/resource-dropdown.vue";

const ApiResources = RestResources();

export default defineComponent({
  name: "contact-us",
  components: {
    ResourceList,
  },
  data() {
    return {
      rowData: [],
      loading: false,
      editType: "fullRow",
      frameworkComponents: {},
      paginationData: {},
      columnDefs: [
        {
          headerName: "First Name",
          field: "firstname",
          sortable: true,
          filter: true,
          editable: true,
          resizable: true,
        },
        {
          headerName: "Last Name",
          field: "lastname",
          sortable: true,
          filter: true,
          editable: true,
          resizable: true,
        },
        {
          headerName: "Contact Number",
          field: "contact_number",
          sortable: true,
          filter: true,
          editable: true,
          resizable: true,
        },
        {
          headerName: "Email",
          field: "email",
          sortable: true,
          filter: true,
          editable: true,
          resizable: true,
        },
        {
          headerName: "Message",
          field: "message",
          sortable: true,
          filter: true,
          editable: true,
          resizable: true,
        },
        {
          headerName: "Updated Time",
          field: "updated_time",
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params: any) => {
            return params.data.updated_time || "";
          },
        },
        {
          headerName: "Created Time",
          field: "createdDate",
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params: any) => {
            return params.data.createdDate || "";
          },
        }
      ],
      btn_options: {
        button: "Add",
        icon: "fa fa-plus",
        class: "btn bg-theme float-end text-white d-none",
      },
    };
  },
  async mounted() {
    this.frameworkComponents = {
      ResourceDropdown: ResourceDropdown,
    };
    this.loading = true;
    this.getContactUs();
  },
  methods: {
    async getContactUs(page = 1, search = "", pageLink = "", date: any = {}) {
      let url = pageLink
        ? pageLink
        : `${process.env.VUE_APP_ROOT_API}/contact-us?page=${page}&page_size=${
            this.$store.state.page_size
          }${search ? `&search=${search}` : ""}${
            date.start ? `&created_time__gte=${date.start}` : ""
          }${date.end ? `&created_time__lte=${date.end}` : ""}`;
      try {
        let contact_us = await ApiResources.getListResource(url).list();
        this.loading = false;
        let { result, ...paginationData } = contact_us.response.data;
        this.rowData = result;
        this.paginationData = paginationData;
      } catch (err: any) {
        this.loading = false;
        if (err.response.status == 401) {
          this.displayMessage([
            {
              text: err.response.data.message,
              variant: "danger",
              timeoutMs: 5000,
              position: "top-0 end-0",
            },
          ]);
        }
      }
    },
    async editResource(data: any) {
      let updateResource = await ApiResources.Contact.wrap(`/${data._id}`);
      let result = await updateResource.patch(data.resource);
      this.displayMessage([
        {
          text: result.data.message,
          variant: result.status == 200 ? "success" : "warning",
          timeoutMs: 5000,
          position: "top-0 end-0",
        },
      ]);
      this.getContactUs();
    },
    getPageSize() {
      return this.$store.state.dropdown_page_size;
    },

    renderActions(params: any) {
      const eDiv = document.createElement("div");
      const showContactUs: any = this.showContactUs;
      const confirmDelete: any = this.confirmDelete;

      eDiv.innerHTML =
        '<span class="productCategories-actions"><button class="btn btn-simple-e" title="View ContactUs"><i class="fas fa-eye"></i></button><button class="btn btn-simple-d" title="Delete Product Categories"><i class="fas fa-trash-alt"></i></button></span>';
      var eButtonD = eDiv.querySelectorAll(".btn-simple-d")[0];

      var eButtonE = eDiv.querySelectorAll(".btn-simple-e")[0];
      eButtonE.addEventListener("click", function () {
        showContactUs(params.data);
      });

      eButtonD.addEventListener("click", function () {
        confirmDelete(params.data);
      });
      return eDiv;
    },

    async confirmDelete(data: any) {
      this.showPopup({
        title: "Delete",
        text: `Are you sure, you want to delete ${data.name} ?`,
        confirmYes: "Delete",
        confirmNo: "Cancel",
        _id: data._id,
      });
    },
    async showContactUs(data: any) {
      let result;
      if (data) {
        let resourceEndPoint = await ApiResources.Contact.wrap(`/${data._id}`);
        result = await resourceEndPoint.get();
      }
      (this.$refs["resource-details"] as any).showDetailModal(
        data ? result.data || {} : {}
      );
    },
    async deleteResource(_id: any) {
      let resourceEndPoint = await ApiResources.Contact.wrap(`/${_id}`);
      let result = await resourceEndPoint.delete();
      this.displayMessage([
        {
          text: result.data.message,
          variant: result.status == 200 ? "success" : "warning",
          position: "top-0 end-0",
          timeoutMs: 5000,
        },
      ]);
      this.getContactUs();
    },
  },
});
